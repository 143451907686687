import React from 'react';
import { Link } from  'gatsby';
import '../../common/blob/blob.js';
import Announcement from '../Announcement';

if (typeof window !== 'undefined') {
  // Make scroll behavior of internal links smooth
  require('smooth-scroll')('a[href*="#"]');
}
let sessionStorageItem;

class HomeHero extends React.Component {

  componentDidMount() {
    const AOS = require("aos");
    AOS.init();
  }

  render() {

    return (
    <section className="Section HomeHero">
      <div className="container-fluid HomeHero-container" >
        <div className="wrapper">
          <div className="row HomeHero-row">
            <div className="col-xs-14 col-md-12 col-md-offset-1 col-xl-10 HomeHero-main">
              <div>
                <div className="HomeHero-main-text">
                  <div className="HomeHero-main-text-title">
                    <Announcement direction="horizontal" text="Magic Developer Alpha now available"/>
                    <h1 className="Heading-h1 light HomeHero-main-title">Internet Access from the Future</h1>
                    <p className="Caption Caption-C1 HomeHero-main-p1">Magic decentralizes access to the internet. Powered by carriers both big and small, Magic is a network of networks — covering the world with secure, fast, and reliable connectivity.</p>
                    <span className="HomeHero-main-text-title-span"></span>
                  </div>
                  <div className="HomeHero-main-text-cta">
                    <div className="HomeHero-main-buttons">
                      <Link to="/waitlist/" className="BtnFilled BtnFilled-large purple uppercase" onClick={()=>window.analytics.track('Clicked Waitlist CTA',{component:'hero'})}><span>Sign up now</span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-5 col-md-5 HomeHero-img">
              <img data-aos="zoom-in" data-aos-duration="800" data-aos-delay={(sessionStorageItem === "true" ? "2000" : "0")} src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/bao_highres_1_comp.png" alt="bau" />
            </div>
            <div className="col-xs-14 col-sm-14 HomeHero-scroll">
              <a href="#homeSection2">
                <p className="Caption-C3 light uppercase">Scroll</p>
                <svg width="18" height="10" viewBox="0 0 18 10" xmlns="http://www.w3.org/2000/svg"><path d="M17.253.022L18 .639 9.55 10 0 .66.706 0l8.798 8.605z" fill="#FFF" fillRule="nonzero"/></svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    );
  }

}



export default HomeHero;
