import React from 'react';
import {Link} from "gatsby";


const RainbowCTA = props => 
  (<div className="Section RainbowCTA">
    <div className="container-fluid">
      <div className="row">
        <div className="col-xs-14 col-md-14 col-lg-12 col-lg-offset-2 col-xl-9 col-xl-offset-4">
          {props.title ?
            <p className="RainbowCTA-text Special-s1 light">{props.title}</p> :
            <p className="RainbowCTA-text Special-s1 light">Get early access to Magic</p>
          }
          <Link
            to="/waitlist/"
            className="RainbowCTA-btn BtnText BtnText-white Special-s1 font-medium light"
            onClick={()=>window.analytics.track('Clicked Waitlist CTA',{component:'rainbowCta'})}
          >
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  </div>);

export default RainbowCTA;

