import React from 'react';
import {Link} from "gatsby";


const InfoSectionSmall = props =>
  (<div className="InfoSectionSmall">
    <p className="InfoSectionSmall-cap Caption-C3 light uppercase">{props.data[0].desc}</p>
    <h2 className={"Heading-h2 InfoSectionSmall-header color-" + props.data[0].color}>{props.data[0].title}</h2>
    <p className="InfoSectionSmall-p BodyText-B2">{props.data[0].paragraph}</p>
    {props.data[0].linkurl &&
    <Link
      to={props.data[0].linkurl}
      className={"InfoSectionSmall-btn BtnText BtnText-" + props.data[0].color + " light font-mono-regular"}
    >
      {props.data[0].linktext}
    </Link>}
    {props.data[0].linkurlOutside &&
    /*eslint-disable-next-line*/
    <a href={props.data[0].linkurlOutside} target="_blank" rel="noopener"
      className={"InfoSectionSmall-btn BtnText BtnText-" + props.data[0].color + " light font-mono-regular"}
    >
      {props.data[0].linktext}
    </a>}
  </div>);

export default InfoSectionSmall;
