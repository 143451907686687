/* global graphql */

import React from 'react';
import HomeHero from '../components/home-hero';
import InfoSectionBasic from '../components/InfoSection/InfoSectionBasic';
import RainbowCTA from '../components/RainbowCTA';
import CalloutUnderline from '../components/Callout/CalloutUnderline';
import InfoSectionSmall from '../components/InfoSection/InfoSectionSmall';
import RainbowTout from '../components/Tout/RainbowTout';
import TemplateWrapper from '../components/TemplateWrapper';
import { graphql } from "gatsby";
import 'aos/dist/aos.css';

class IndexPage extends React.Component {

  componentDidMount() {
    const AOS = require("aos");
    AOS.init({
      once: true
    })
  }

  render() {

    const {location, data} = this.props;

    return (<TemplateWrapper location={location}>
      <main className="Home">
        <p style={{position: `absolute`, opacity: `0`}} className="font-mono-semi-bold">magic</p>
        <p style={{position: `absolute`, opacity: `0`}} className="font-mono-medium">magic</p>
        <HomeHero />
        <section id="homeSection2" className="Section Home-InfoSection">
          <div className="container-fluid">
            <div className="wrapper">
              <div className="row Home-InfoSection-one">
                <div className="col-xs-7 Home-InfoSection-one-image-mobile hide-md hide-lg hide-xl">
                  <svg className="Home-InfoSection-one-img hide-md hide-lg hide-xl" viewBox="0 0 157 120"
                       xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fillRule="evenodd">
                      <path fill="#000" d="M-10-1954h414V9031H-10z"/>
                      <g fill="#FFF" fillRule="nonzero">
                        <ellipse cx="5.002" cy="4.5" rx="4.434" ry="4.5"/>
                        <ellipse cx="41.787" cy="4.5" rx="4.434" ry="4.5"/>
                        <ellipse cx="78.571" cy="4.5" rx="4.434" ry="4.5"/>
                        <ellipse cx="115.355" cy="4.5" rx="4.434" ry="4.5"/>
                        <ellipse cx="152.14" cy="4.5" rx="4.434" ry="4.5"/>
                        <ellipse cx="5.002" cy="41.5" rx="4.434" ry="4.5"/>
                        <ellipse cx="41.787" cy="41.5" rx="4.434" ry="4.5"/>
                        <ellipse cx="78.571" cy="41.5" rx="4.434" ry="4.5"/>
                        <ellipse cx="115.355" cy="41.5" rx="4.434" ry="4.5"/>
                        <ellipse cx="152.14" cy="41.5" rx="4.434" ry="4.5"/>
                        <ellipse cx="5.002" cy="78" rx="4.434" ry="4.5"/>
                        <ellipse cx="41.787" cy="78" rx="4.434" ry="4.5"/>
                        <ellipse cx="78.571" cy="78" rx="4.434" ry="4.5"/>
                        <ellipse cx="115.355" cy="78" rx="4.434" ry="4.5"/>
                        <ellipse cx="152.14" cy="78" rx="4.434" ry="4.5"/>
                        <ellipse cx="5.002" cy="115" rx="4.434" ry="4.5"/>
                        <ellipse cx="41.787" cy="115" rx="4.434" ry="4.5"/>
                        <ellipse cx="78.571" cy="115" rx="4.434" ry="4.5"/>
                        <ellipse cx="115.355" cy="115" rx="4.434" ry="4.5"/>
                        <ellipse cx="152.14" cy="115" rx="4.434" ry="4.5"/>
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="col-xs-13 col-md-5 col-md-offset-1">
                  <InfoSectionBasic data={data.allDataJson.edges[0].node.homeRow1}/>
                </div>
                <div className="col-md-5 col-md-offset-2 hide-xs hide-sm Home-InfoSection-one-image">
                  <svg className="Home-InfoSection-one-img hide-xs hide-sm" viewBox="0 0 460 459"
                       xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fillRule="evenodd">
                      <path d="M-35-2229h1440v7607H-35z"/>
                      <g fill="#FFF" fillRule="nonzero">
                        <ellipse cx="8.868" cy="9" rx="8.868" ry="9"/>
                        <ellipse cx="82.436" cy="9" rx="8.868" ry="9"/>
                        <ellipse cx="156.005" cy="9" rx="8.868" ry="9"/>
                        <ellipse cx="229.574" cy="9" rx="8.868" ry="9"/>
                        <ellipse cx="303.142" cy="9" rx="8.868" ry="9"/>
                        <ellipse cx="376.711" cy="9" rx="8.868" ry="9"/>
                        <ellipse cx="450.279" cy="9" rx="8.868" ry="9"/>
                        <ellipse cx="8.868" cy="83" rx="8.868" ry="9"/>
                        <ellipse cx="82.436" cy="83" rx="8.868" ry="9"/>
                        <ellipse cx="156.005" cy="83" rx="8.868" ry="9"/>
                        <ellipse cx="229.574" cy="83" rx="8.868" ry="9"/>
                        <ellipse cx="303.142" cy="83" rx="8.868" ry="9"/>
                        <ellipse cx="376.711" cy="83" rx="8.868" ry="9"/>
                        <ellipse cx="450.279" cy="83" rx="8.868" ry="9"/>
                        <ellipse cx="8.868" cy="156" rx="8.868" ry="9"/>
                        <ellipse cx="82.436" cy="156" rx="8.868" ry="9"/>
                        <ellipse cx="156.005" cy="156" rx="8.868" ry="9"/>
                        <ellipse cx="229.574" cy="156" rx="8.868" ry="9"/>
                        <ellipse cx="303.142" cy="156" rx="8.868" ry="9"/>
                        <ellipse cx="376.711" cy="156" rx="8.868" ry="9"/>
                        <ellipse cx="450.279" cy="156" rx="8.868" ry="9"/>
                        <ellipse cx="8.868" cy="230" rx="8.868" ry="9"/>
                        <ellipse cx="82.436" cy="230" rx="8.868" ry="9"/>
                        <ellipse cx="156.005" cy="230" rx="8.868" ry="9"/>
                        <ellipse cx="229.574" cy="230" rx="8.868" ry="9"/>
                        <ellipse cx="303.142" cy="230" rx="8.868" ry="9"/>
                        <ellipse cx="376.711" cy="230" rx="8.868" ry="9"/>
                        <ellipse cx="450.279" cy="230" rx="8.868" ry="9"/>
                        <ellipse cx="8.868" cy="303" rx="8.868" ry="9"/>
                        <ellipse cx="82.436" cy="303" rx="8.868" ry="9"/>
                        <ellipse cx="156.005" cy="303" rx="8.868" ry="9"/>
                        <ellipse cx="229.574" cy="303" rx="8.868" ry="9"/>
                        <ellipse cx="303.142" cy="303" rx="8.868" ry="9"/>
                        <ellipse cx="376.711" cy="303" rx="8.868" ry="9"/>
                        <ellipse cx="450.279" cy="303" rx="8.868" ry="9"/>
                        <ellipse cx="8.868" cy="377" rx="8.868" ry="9"/>
                        <ellipse cx="82.436" cy="377" rx="8.868" ry="9"/>
                        <ellipse cx="156.005" cy="377" rx="8.868" ry="9"/>
                        <ellipse cx="229.574" cy="377" rx="8.868" ry="9"/>
                        <ellipse cx="303.142" cy="377" rx="8.868" ry="9"/>
                        <ellipse cx="376.711" cy="377" rx="8.868" ry="9"/>
                        <ellipse cx="450.279" cy="377" rx="8.868" ry="9"/>
                        <ellipse cx="8.868" cy="450" rx="8.868" ry="9"/>
                        <ellipse cx="82.436" cy="450" rx="8.868" ry="9"/>
                        <ellipse cx="156.005" cy="450" rx="8.868" ry="9"/>
                        <ellipse cx="229.574" cy="450" rx="8.868" ry="9"/>
                        <ellipse cx="303.142" cy="450" rx="8.868" ry="9"/>
                        <ellipse cx="376.711" cy="450" rx="8.868" ry="9"/>
                        <ellipse cx="450.279" cy="450" rx="8.868" ry="9"/>
                      </g>
                    </g>
                  </svg>
                  <span data-aos="custom-reveal-top"></span>
                </div>
              </div>
              <div className="row Home-InfoSection-two">
                <div className="col-xs-14 col-md-5 Home-InfoSection-two-img-cont">
                  <img className="Home-InfoSection-two-img"
                       src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/home_info_img3.png"
                       alt="rainbow"/>
                </div>
                <div className="col-xs-13 col-md-5 col-md-offset-1">
                  <InfoSectionBasic data={data.allDataJson.edges[0].node.homeRow2}/>
                </div>
              </div>
              <div className="row Home-InfoSection-three">
                <div className="col-xs-14 hide-md hide-lg hide-xl">
                  <img className="Home-InfoSection-three-img"
                       src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/home-laptop.png"
                       alt="rainbow"/>
                </div>
                <div className="col-xs-13 col-md-5 col-md-offset-1">
                  <InfoSectionBasic data={data.allDataJson.edges[0].node.homeRow3}/>
                </div>
                <div className="col-md-6 col-md-offset-1 hide-xs hide-sm">
                  <img className="Home-InfoSection-three-img"
                       src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/home-laptop.png"
                       alt="rainbow"/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Section Home-rainbow-tout">
          <div className="container-fluid">
            <div className="wrapper">
              <div className="row">
                <div className="col-xs-14 col-md-12 col-md-offset-1 col-lg-10 col-lg-offset-2 NetworkPage-rainbow-tout-container">
                  <RainbowTout title="Download the Alpha" paragraph1="Magic Alpha is available now for developers. Included is Magic Client and Magic Agent software to enable Magic WiFi using supported routers." paragraph2="Follow along on our GitHub repo and help us define the future of internet access. We can’t wait to hear your feedback." href="/waitlist" btnText="Sign up and download" trackEvent="Clicked Waitlist CTA"/>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*<section data-aos="fade-up"*/}
                 {/*data-aos-offset="200"*/}
                 {/*data-aos-delay="50"*/}
                 {/*data-aos-duration="1000"*/}
                 {/*data-aos-easing="ease-in-out"*/}
                 {/*className="Section Home-HowItWorksSection">*/}
          {/*<div className="container-fluid">*/}
            {/*<div className="wrapper">*/}
              {/*<div className="row">*/}
                {/*<div className="col-xs-14 col-md-12 col-md-offset-1">*/}
                  {/*<h1 className="Heading Heading-h1 light Home-HowItWorksSection-title">How it Works</h1>*/}
                {/*</div>*/}
              {/*</div>*/}
              {/*<div className="row">*/}
                {/*<div className="col-xs-14 col-md-4 col-md-offset-1 Home-HowItWorksSection-tout-container">*/}
                  {/*<ToutWhite data={data.allDataJson.edges[0].node.homeTout1}/>*/}
                {/*</div>*/}
                {/*<div className="col-xs-14 col-md-4 Home-HowItWorksSection-tout-container">*/}
                  {/*<ToutWhite data={data.allDataJson.edges[0].node.homeTout2}/>*/}
                {/*</div>*/}
                {/*<div className="col-xs-14 col-md-4 Home-HowItWorksSection-tout-last">*/}
                  {/*<ToutWhite data={data.allDataJson.edges[0].node.homeTout3}/>*/}
                  {/*<ToutWhite data={data.allDataJson.edges[0].node.homeTout4}/>*/}
                {/*</div>*/}
              {/*</div>*/}
            {/*</div>*/}
          {/*</div>*/}
        {/*</section>*/}
        <section data-aos="fade-up"
                 data-aos-offset="200"
                 data-aos-delay="50"
                 data-aos-duration="1000"
                 data-aos-easing="ease-in-out"
                 className="Section Home-callout-one">
          <div className="container-fluid">
            <div className="wrapper">
              <div className="row">
                <div className="col-xs-14 col-sm-12 col-sm-offset-1">
                  <CalloutUnderline underlineColor="purple" lineOne="The future is bright."
                                    lineTwo="And a bit magical."/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section data-aos="fade-up"
                 data-aos-offset="200"
                 data-aos-delay="50"
                 data-aos-duration="1000"
                 data-aos-easing="ease-in-out"
                 className="Section Home-bottom-info">
          <div className="container-fluid">
            <div className="wrapper">
              <div className="row">
                <div className="col-xs-14 col-md-12 col-md-offset-1">
                  <p className="Special-s2 Home-bottom-info-title">Learn &mdash;</p>
                </div>
                <div className="col-xs-14 col-md-3 col-md-offset-1">
                  <InfoSectionSmall data={data.allDataJson.edges[0].node.homeLearn1}/>
                </div>
                <div className="col-xs-14 col-md-3 col-md-offset-1">
                  <InfoSectionSmall data={data.allDataJson.edges[0].node.homeLearn2}/>
                </div>
                <div className="col-xs-14 col-md-3 col-md-offset-1">
                  <InfoSectionSmall data={data.allDataJson.edges[0].node.homeLearn3}/>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*<section data-aos="fade-up"*/}
        {/*         data-aos-offset="200"*/}
        {/*         data-aos-delay="50"*/}
        {/*         data-aos-duration="1000"*/}
        {/*         data-aos-easing="ease-in-out"*/}
        {/*         className="Section Home-bottom-info">*/}
        {/*  <div className="container-fluid">*/}
        {/*    <div className="wrapper">*/}
        {/*      <div className="row">*/}
        {/*        <div className="col-xs-14 col-md-12 col-md-offset-1">*/}
        {/*          <p className="Special-s2 Home-bottom-info-title">Explore &mdash;</p>*/}
        {/*        </div>*/}
        {/*        <div className="col-xs-14 col-md-3 col-md-offset-1">*/}
        {/*          <InfoSectionSmall data={data.allDataJson.edges[0].node.homeExplore1}/>*/}
        {/*        </div>*/}
        {/*        <div className="col-xs-14 col-md-3 col-md-offset-1">*/}
        {/*          <InfoSectionSmall data={data.allDataJson.edges[0].node.homeExplore2}/>*/}
        {/*        </div>*/}
        {/*        <div className="col-xs-14 col-md-3 col-md-offset-1">*/}
        {/*          <InfoSectionSmall data={data.allDataJson.edges[0].node.homeExplore3}/>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</section>*/}
        <div className="Home-RainbowCTA-container" data-aos="fade-up"
             data-aos-offset="200"
             data-aos-delay="50"
             data-aos-duration="1000"
             data-aos-easing="ease-in-out">
          <RainbowCTA/>
        </div>
      </main>
    </TemplateWrapper>);
  }
}

export default IndexPage;



export const pageQuery = graphql`
  query IndexQuery {
    allDataJson {
      edges {
        node {
          homeRow1 {
            title,
            title2,
            paragraph1,
            paragraph2
          }
          homeRow2 {
            title,
            title2,
            paragraph1,
            paragraph2
          }
          homeRow3 {
            title,
            title2,
            paragraph1,
            paragraph2
          }
          homeTout1 {
            color,
            title,
            paragraph1,
            paragraph2,
            paragraph3,
            link
          }
          homeTout2 {
            color,
            title,
            paragraph1,
            paragraph2,
            paragraph3,
            link
          }
          homeTout3 {
            color,
            title,
            paragraph1,
            paragraph2,
            paragraph3,
            link
          }
          homeTout4 {
            color,
            title,
            paragraph1,
            paragraph2,
            paragraph3,
            link
          }
          homeLearn1 {
            color,
            desc,
            title,
            paragraph,
            linkurlOutside,
            linktext
          }
          homeLearn2 {
            color,
            desc,
            title,
            paragraph,
            linkurl,
            linkurlOutside,
            linktext
          }
          homeLearn3 {
            color,
            desc,
            title,
            paragraph,
            linkurlOutside,
            linktext
          }
          homeExplore1 {
            color,
            desc,
            title,
            paragraph,
            linkurl,
            linktext
          }
          homeExplore2 {
            color,
            desc,
            title,
            paragraph,
            linkurl,
            linktext
          }
          homeExplore3 {
            color,
            desc,
            title,
            paragraph,
            linkurl,
            linktext
          }
        }
      }
    }
  }
`;






