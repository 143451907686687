import React from 'react';
import { Link } from "gatsby";


const RainbowTout = props =>
  (<div className="RainbowTout">
    <div className="RainbowTout-rainbow"></div>
    <div className="RainbowTout-content">
      <h2 className="Heading Heading-h2 dark RainbowTout-content-title">{props.title}</h2>
      <p className="BodyText BodyText-B2 dark RainbowTout-content-p">{props.paragraph1}</p>
      {props.paragraph2 && <p className="BodyText BodyText-B2 dark RainbowTout-content-p">{props.paragraph2}</p>}
      <Link 
        to={props.href}
        className="Btn BtnText BtnText-purple dark RainbowTout-content-btn" 
        onClick={()=>window.analytics.track((props.trackEvent ? props.trackEvent: 'Clicked Misc CTA'),{component:'rainbowTout'})}>
          {props.btnText}
      </Link>


    </div>
  </div>);

export default RainbowTout;
