import React from 'react';


const CalloutUnderline = props =>
  (<div className="CalloutUnderline">
    <h1 className={"CalloutUnderline-text Heading-d1 underline-"+ props.underlineColor}>
      <span>{props.lineOne}</span>
    </h1>
    {props.lineTwo &&
      <h1 className={"CalloutUnderline-text Heading-d1 underline-"+ props.underlineColor}>
        <span>{props.lineTwo}</span>
      </h1>
    }
    {props.lineThree &&
    <h1 className={"CalloutUnderline-text Heading-d1 underline-"+ props.underlineColor}>
      <span>{props.lineThree}</span>
    </h1>
    }
  </div>);

export default CalloutUnderline;
