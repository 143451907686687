import React from 'react';
import {Link} from "gatsby";


const Announcement = props =>
  (<div className="Announcement">
    { props.direction === "horizontal" ?
      <div className="Announcement-horizontal">
          <p className="Caption Caption-C3 color-green font-medium Announcement-horizontal-new">NEW</p>
          <p className="BodyText BodyText-B1 light font-regular">{props.text}</p>
      </div> :
      <div className="Announcement-vertical">
        <p className="Caption Caption-C3 color-green font-mono-medium ">NEW</p>
        <p className="BodyText BodyText-B1 light font-mono-regular uppercase">{props.text}</p>
        { props.text2 &&
          <p className="BodyText BodyText-B1 light font-mono-regular uppercase">{props.text2}</p>
        }
      </div>
    }
  </div>);

export default Announcement;

