import React from 'react';


const InfoSectionBasic = props =>
  (<div className="InfoSectionBasic">
    <hr align="left" data-aos="custom-reveal-hr" data-aos-duration="1000" data-aos-delay="800" className={props.data[0].color ? props.data[0].color : "grey"}/>
    <div data-aos="custom-slide-right" data-aos-duration="1500" data-aos-delay="0">
      <p className="InfoSectionBasic-title1 Caption-C3 light uppercase">{props.data[0].title}</p>
      <h2 className="InfoSectionBasic-title2 Heading-h2">{props.data[0].title2}</h2>
      <span data-aos="custom-reveal-right" data-aos-duration="1500"></span>
    </div>
    <div data-aos="fade-up" data-aos-duration="1500">
      <p className="BodyText-B1 InfoSectionBasic-p">{props.data[0].paragraph1}</p>
      {props.data[0].paragraph2 ? <p className="BodyText-B1 InfoSectionBasic-p">{props.data[0].paragraph2}</p> : null}
      {props.data[0].paragraph3 ? <p className="BodyText-B1 InfoSectionBasic-p">{props.data[0].paragraph3}</p> : null}
      {props.data[0].paragraph4 ? <p className="BodyText-B1 InfoSectionBasic-p">{props.data[0].paragraph4}</p> : null}
    </div>
  </div>);

export default InfoSectionBasic;
